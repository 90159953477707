import validate from "/home/forge/postpolish.com/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/forge/postpolish.com/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/forge/postpolish.com/middleware/auth.js"),
  guest: () => import("/home/forge/postpolish.com/middleware/guest.js"),
  "not-enterprise": () => import("/home/forge/postpolish.com/middleware/not-enterprise.js"),
  "without-plan": () => import("/home/forge/postpolish.com/middleware/without-plan.js")
}