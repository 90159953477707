import {defineStore} from 'pinia'

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: undefined,
    }),

    actions: {
        async initAuth() {
            let config = useRuntimeConfig().public

            await fetch(`${config.api_url}/api/user`, {
                headers: {
                    'Authorization': 'Bearer ' + useCookie('token').value,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json()
                    }

                    this.user = null
                })
                .then(response => this.user = response)
                .catch(error => {
                    this.user = null
                })
        },

        updateUser(payload) {
            this.user = payload
        },
    },
})
