import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
    const runtimeConfig = useRuntimeConfig()

    if (process.client) {
        nuxtApp.hook('app:mounted', () => {
            if (window.Paddle) {
                if (runtimeConfig.public.is_sandbox) {
                    window.Paddle.Environment.set('sandbox')
                }

                window.Paddle.Setup({
                    token: runtimeConfig.public.paddle_fe_token,
                    eventCallback: function (data) {
                        if (data.name === 'checkout.completed') {
                            setTimeout(() => {
                                window.location.href = `${window.location.origin}/billing?success=true&message=Subscription was successful`
                            }, 2000)
                        }
                    },
                })
            } else {
                console.error('Paddle.js is not loaded')
            }
        })
    }
})