import {useAuthStore} from "~/stores/auth"

export default defineNuxtPlugin((nuxtApp) => {
    // If this is a redirect, don't do anything
    if ((nuxtApp.payload.path.startsWith('/r'))) {
        return
    }

    useAuthStore().initAuth()
})
